.gt-innerContainer {
    width: 60%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 100px;
    padding-top: 150px;
    padding-bottom: 50px;
}

.test-locations {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 30px;
    text-align: center;
    width: 40%;
    margin: 0 auto;
    padding-bottom: 50px;
}

#gtheading-txt {
    color: #FAEBD7;
}

.gt-locationSelect {
    width: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 20px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

@media (max-width: 600px) {
    .gt-locationSelect {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        gap: 15px;
    }

    #city-input {
        margin-left: 0;
    }

    .test-locations {
        width: 75%;
    }
}

@media (max-width: 520px) {
    .gt-heading, .gt-locationSelect, .state-selectGT {
        margin-bottom: 50px;
    }

    .gt-heading {
        padding-left: 6px;
        padding-right: 6px;
    }
}

