.nav-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    position: absolute;
    padding-top: 10px;
    top: 0;
    width: 100%;
}

@media only screen and (max-width: 500px) {
    .nav-container {
        justify-content: space-between;
        padding: 10px 20px;
    }
}