@media (max-width: 520px) {
    .stats-heading {
        position: relative;
        padding: 0px 10px;
    }
    .state-select, .stats-heading {
        margin-bottom: 70px !important;
    }

}

.state-select {
    outline: none;
}